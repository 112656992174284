@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&display=swap');

.placeinfo {display:grid;grid-template-columns:1fr;grid-template-rows:repeat(auto-fill, minmax(auto,auto));position:relative;width:100%;border-radius:6px;border: 1px solid #ccc;border-bottom:2px solid #ddd;padding-bottom:10px;background: #fff;}
.placeinfo:nth-of-type(n) {border:0; box-shadow:0px 1px 2px #888;}
.placeinfo_wrap .after {content:'';position:relative;margin-left:-12px;left:50%;width:22px;height:12px;background:url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png')}
.placeinfo a, .placeinfo a:hover, .placeinfo a:active{color:#fff;text-decoration: none;}
.placeinfo a, .placeinfo span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.placeinfo span {
    margin:5px 5px 0 5px;
    cursor: default;
    font-size:13px;
}
.placeinfo .title {
    grid-row-start:1;
    grid-row-end:2;
    grid-column-start:1;
    grid-column-end:2;
    font-weight: bold;
    font-size:14px;
    border-radius: 6px 6px 0 0;
    margin: -1px -1px 0 -1px;
    padding:10px;
    color: rgb(0, 0, 0);
    background: #ffe330 url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/arrow_white.png) no-repeat right 14px center;
}
.placeinfo .title:hover {
    color: rgb(0, 0, 0);
}
.placeinfo .tel {
    grid-row-start:3;
    grid-row-end:4;
    grid-column-start:1;
    grid-column-end:2;
    justify-self: end;
    margin: 0px 10px;
    color:#0f7833;
    font-size:small;
}
.placeinfo .address {
    grid-row-start:2;
    grid-row-end:3;
    grid-column-start:1;
    grid-column-end:2;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: small;
    margin: 0px 10px;
}